import {Page} from '#graphql/graphql';
import pageQuery from '#graphql/queries/pages/page.gql';

export default defineNuxtRouteMiddleware(async function (route) {
  const slug = route.path.replace(/^\//, '');
  const {data} = await useAsyncQuery<{ page: Page }>(pageQuery, {segments: slug === '' ? 'home' : slug});

  const page = data.value?.page;
  if (!page) {
    throw createError({statusCode: 404, statusMessage: 'Pagina niet gevonden'});
  }

  route.meta.page = page;

  useHead({
    title: page.title,
  });
});
